export const addLandCoveringFloorAreaVisuals = [
  {
    name: "Demo",
    markdown: `![Add land covering floor area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addBuildingLevelFloorAreaVisuals = [
  {
    name: "Demo",
    markdown: `![Add building level floor area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addExistingAirRightsFloorAreaVisuals = [
  {
    name: "Demo",
    markdown: `![Add existing air rights floor area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addAirRightsFloorAreaVisuals = [
  {
    name: "Demo",
    markdown: `![Add air rights floor area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addFloorArea = {
  order: 18,
  name: "Add a floor area",
  keywords: "space rentable useable usable lease",
  subcategory: "Properties",
  markdown: `# Add a floor area

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  In commercial real estate, {% inlineRouterLink %}floor area{% /inlineRouterLink %} is most often the product that the customer (usually a tenant) wants. Tenants sign leases that grant them temporary use of discrete amounts of space. Real estate operators acquire existing floor area and/or attempt to construct new floor area so that they can lease it to generate revenue. In Tower Hunt, floor area can be added to {% inlineRouterLink %}land coverings{% /inlineRouterLink %}, {% inlineRouterLink %}building levels{% /inlineRouterLink %}, and {% inlineRouterLink %}air rights{% /inlineRouterLink %}.

  ## What to expect

  A newly created floor area just represents *some* amount of space that *may or may not exist* at a {% inlineRouterLink articleId="what-is-a-property?" %}property{% /inlineRouterLink %}. Context can be added via the {% inlineRouterLink %}datapoint{% /inlineRouterLink %} types below, each of which is {% inlineAppIcon iconName="private" /%}{% inlineRouterLink articleId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} by default:

  - {% inlineRouterLink articleId="add-property-uses" %}Property uses{% /inlineRouterLink %} indicate the intended/allowed uses
  - {% inlineRouterLink articleId="add-floor-area-measures" %}Area measures{% /inlineRouterLink %} define how much space
  - {% inlineRouterLink articleId="add-a-milestone-date" %}Milestone dates{% /inlineRouterLink %} govern the status of the space and when it exists
  - {% inlineRouterLink articleId="add-a-floor-area-layout" %}Layouts{% /inlineRouterLink %} specify the quantities and types of rooms/partitions that exist (or are possible)

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Property selector" %}property{% /inlineRouterLink %}, {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}land covering{% /inlineRouterLink %}, {% inlineRouterLink %}building level{% /inlineRouterLink %}, or {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Air rights (optional)" %}air right{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addFloorArea" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more diagram parts to place the floor area on. Selecting multiple parts will cause the floor area to span all of those parts.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. A new floor area appears in the selected diagram parts.
  {% /tablessContainer %}

  ## On a land covering

  Placing a floor area on a land covering can be a helpful starting point when you do not have deeper knowledge of the property. If you {% inlineRouterLink %}add building levels{% /inlineRouterLink %} later, the existing floor area(s) will automatically migrate and you can then {% inlineRouterLink %}relocate them{% /inlineRouterLink %} as needed.

  {% tabbedContainer tabs=$addLandCoveringFloorAreaVisuals marginStyles="mb-4" /%}

  {% callout type="tip" %}
  **Tip:** You can {% inlineRouterLink %}vertically rearrange{% /inlineRouterLink %} land covering floor areas. This is useful when {% inlineRouterLink %}property uses{% /inlineRouterLink %} are added and you'd like a certain type of space to appear in a certain part of the building.
  {% /callout %}

  ## On a building level

  Placing a floor area on one or more building levels allows for more visual precision when you have deeper knowledge.

  {% tabbedContainer tabs=$addBuildingLevelFloorAreaVisuals marginStyles="mb-4" /%}

  {% callout type="tip" marginStyles="mb-4" %}
  **Tip:** You can quickly {% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Selecting multiple building levels" %}select a range of building levels{% /inlineRouterLink %}. This is useful in tall buildings instead of clicking each building level individually.
  {% /callout %}

  {% callout type="tip" %}
  **Tip:** You can {% inlineRouterLink %}horizontally rearrange{% /inlineRouterLink %} floor areas within a building level. Use this if there are multiple contiguous floor areas that you want to appear visually together.
  {% /callout %}

  ## On existing air rights

  Placing floor areas on air rights helps to keep potential/hypothetical space separate from what currently exists.

  {% tabbedContainer tabs=$addExistingAirRightsFloorAreaVisuals /%}

  ## On new air rights

  When the property diagram does not have any air rights in it, {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**select**{% /inlineRouterLink %} the {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Fee parcels" %}fee parcel(s){% /inlineRouterLink %} where the air rights belong and Tower Hunt will create them when adding the floor area.

  {% tabbedContainer tabs=$addAirRightsFloorAreaVisuals /%}
  `,
};
